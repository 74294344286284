<template>
  <v-alert class="alert-style" prominent :color="backgroundColor">
    <v-row align="center" no-gutters>
      <v-icon class="mr-2 pl-2 pr-2" :color="iconAlertColor">
        {{ iconName }}
      </v-icon>
      <v-col class="grow">
        {{ textAlert }}
      </v-col>
      <v-col class="shrink pr-3">
        <font-awesome-icon
          class="icon-style"
          :color="iconEndColor"
          :icon="iconFontAwesome"
          @click="close"
        />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'MessageDataInvalid',

  props: {
    backgroundColor: {
      type: String,
      default: '',
    },
    iconAlertColor: {
      type: String,
      default: '',
    },
    iconEndColor: {
      type: String,
      default: '',
    },
    iconFontAwesome: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    textAlert: {
      type: String,
      default: '',
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.alert-style {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px !important;
  width: 100%;
}

.icon-style {
  cursor: pointer;
}
</style>
