<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      lg="4"
      md="5"
      sm="12"
      class="px-6 px-md-12 py-4 d-flex flex-column"
    >
      <v-row class="header-login d-flex justify-space-between">
        <v-col class="mx-0" md="5">
          <img
            alt="digifarmz-icon"
            src="img/digifarmz_yellow.webp"
            width="160px"
          />
        </v-col>
        <v-col md="6" class="header-login_language-selector mx-0">
          <language-selector />
        </v-col>
      </v-row>
      <v-row
        class="d-flex justify-center align-center"
        v-if="!user && !recoverPassword && !sendEmail"
      >
        <v-col cols="12" no-gutters>
          <v-row no-gutters class="welcome-text">
            {{ $t('login.welcome_back') }}
          </v-row>
          <v-row no-gutters class="welcome-secundary-text">
            {{ $t('login.welcome_message') }}
          </v-row>
          <v-row no-gutters class="options-label pt-4">
            {{ $t('login.email') }}
          </v-row>
          <v-text-field
            v-model="email"
            color="#39af49"
            dense
            hide-details
            outlined
            solo
            :placeholder="$t('login.digit_email')"
          >
            <template #append>
              <font-awesome-icon class="icon-outlined" icon="envelope" />
            </template>
          </v-text-field>
          <v-row no-gutters class="options-label pt-4">
            {{ $t('login.password') }}
          </v-row>
          <v-text-field
            v-model="password"
            :placeholder="$t('login.digit_password')"
            solo
            outlined
            dense
            hide-details
            color="#39af49"
            type="password"
            @keyup.enter="login"
          >
            <template #append>
              <font-awesome-icon class="icon-outlined" icon="key" />
            </template>
          </v-text-field>
          <v-row no-gutters class="pt-7">
            <v-btn
              class="text-none platform-access-button"
              width="100%"
              :loading="isLoading"
              @click="login"
            >
              <span class="platform-access">
                {{ $t('login.platform_access') }}
              </span>
              <font-awesome-icon class="icon-access" icon="sign-in-alt" />
            </v-btn>
          </v-row>
          <v-row
            no-gutters
            class="pt-6 align-center justify-center"
            offset-md="3"
          >
            <a
              class="text-link text-none"
              @click.prevent="goToRecoveryPassword"
            >
              {{ $t('login.forgot_password') }}</a
            >
            <font-awesome-icon class="icon-link" icon="key" />
          </v-row>
          <v-row>
            <message-data-invalid
              v-if="emailPasswordInvalid"
              background-color="#FFE1DF"
              icon-alert-color="#F23528"
              icon-end-color="#F23528"
              icon-font-awesome="window-close"
              icon-name="mdi-alert"
              :text-alert="customMessage"
              @close="emailPasswordInvalid = false"
            />
            <div v-else class="empty-alert"></div>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="d-flex justify-center align-center"
        v-else-if="user && onlyOneAccount"
      >
        <v-col lg="8" sm="8" md="10" xs="12">
          <v-row no-gutters class="select-account">
            {{ $t('login.select_account') }}
          </v-row>
          <v-select
            class="custom-select pb-6"
            v-model="selectedAccountId"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            color="#39af49"
            :items="accounts"
          />
          <v-row no-gutters class="access-account">
            <v-btn
              class="text-none platform-access-button"
              width="100%"
              :loading="isLoading"
              @click="selectedAccount"
            >
              <span class="platform-access">
                {{ $t('login.platform_access') }}
              </span>
              <font-awesome-icon class="icon-access" icon="sign-in-alt" />
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="d-flex justify-center align-center"
        v-else-if="!user && recoverPassword"
      >
        <v-col cols="12" no-gutters>
          <v-row class="recover-password">
            {{ $t('login.recover_password') }}
          </v-row>
          <v-row no-gutters class="options-label pt-4">
            {{ $t('login.email') }}
          </v-row>
          <v-text-field
            v-model="email"
            :placeholder="$t('login.digit_email')"
            solo
            outlined
            dense
            hide-details
            color="#39af49"
          >
            <template #append>
              <font-awesome-icon class="icon-outlined" icon="envelope" />
            </template>
          </v-text-field>
          <v-row no-gutters class="access-account">
            <v-btn
              class="text-none platform-access-button"
              width="100%"
              :loading="isLoading"
              @click="recoverPasswordLogin"
            >
              <span class="platform-access">
                {{ $t('login.send_email') }}
              </span>
              <font-awesome-icon class="icon-access" icon="paper-plane" />
            </v-btn>
          </v-row>
          <v-row
            no-gutters
            class="pt-6 align-center justify-center"
            offset-md="3"
          >
            <a class="text-link text-none" @click.prevent="backToLogin">
              {{ $t('login.back_to_login') }}</a
            >
            <font-awesome-icon class="icon-link" icon="arrow-right" />
          </v-row>
          <v-row>
            <message-data-invalid
              v-if="emailPasswordInvalid"
              background-color="#FFE1DF"
              icon-alert-color="#F23528"
              icon-end-color="#F23528"
              icon-font-awesome="window-close"
              icon-name="mdi-alert"
              :text-alert="customMessage"
              @close="emailPasswordInvalid = false"
            />
            <div v-else class="empty-alert"></div>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="d-flex justify-center align-center"
        v-else-if="!user && !recoverPassword && sendEmail"
      >
        <v-col cols="12" class="d-flex flex-column align-center">
          <div class="background-help">
            <font-awesome-icon class="help" icon="check" />
          </div>
          <div class="text-center need-help">
            {{ $t('login.check_email') }}
          </div>
          <div
            class="text-center contact"
            v-html="$t('login.check_email_text')"
          ></div>
        </v-col>
      </v-row>
      <footer-support />
    </v-col>
    <v-col
      v-if="!isMobile"
      cols="12"
      lg="8"
      md="7"
      sm="0"
      class="farmer-img__wrapper"
    >
      <img class="login-side-image" alt="farmer" src="img/farmer-login.webp" />
    </v-col>
  </v-row>
</template>

<script>
import MessageDataInvalid from '@/components/MessageDataInvalid.vue'
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import FooterSupport from '@/modules/auth/components/FooterSupport/FooterSupport.vue'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Auth',

  mixins: [LayoutMixin],

  data() {
    return {
      email: '',
      password: '',
      user: null,
      accounts: [],
      sendEmail: false,
      recoverPassword: false,
      selectedAccountId: null,
      emailPasswordInvalid: false,
      language: null,
      isLoading: false,
    }
  },

  components: {
    MessageDataInvalid,
    LanguageSelector,
    FooterSupport,
  },

  created() {
    this.language = this.$root.$i18n.locale
  },

  watch: {
    '$root.$i18n.locale': function (language) {
      this.language = language
    },
  },

  computed: {
    ...mapState('permissions', ['permissions']),
    onlyOneAccount() {
      return this.accounts?.length > 1
    },
    errorEmailInvalid() {
      return !this.user && this.recoverPassword
    },
    customMessage() {
      return this.errorEmailInvalid
        ? this.$t('login.email_invalid')
        : this.$t('login.email_password_invalid')
    },
  },

  methods: {
    goToRecoveryPassword() {
      this.emailPasswordInvalid = false
      this.recoverPassword = true
    },
    backToLogin() {
      this.emailPasswordInvalid = false
      this.recoverPassword = false
    },
    async getAccountSelected(accountId) {
      await this.$api.auth.selectAccount(accountId)
      this.$router.push({
        path: '/my_farm',
      })
    },
    async selectedAccount() {
      try {
        this.isLoading = true
        await this.getAccountSelected(this.selectedAccountId)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    async login() {
      try {
        this.isLoading = true
        this.emailPasswordInvalid = false
        const response = await this.$api.auth.login(this.email, this.password)

        if (response.data) {
          this.user = response?.data
          this.accounts = this.user.accounts
          this.selectedAccountId = this.accounts[0].id
          Cookies.set('token', response.data.token)
        }
        if (this.accounts.length === 1) {
          await this.getAccountSelected(this.accounts[0].id)
        }
      } catch (error) {
        this.emailPasswordInvalid = true
      } finally {
        this.isLoading = false
      }
    },
    async recoverPasswordLogin() {
      this.isLoading = true
      this.emailPasswordInvalid = false
      try {
        const response = await this.$api.auth.recoverPassword(this.email)
        if (response.data) {
          this.recoverPassword = false
          this.sendEmail = true
          setTimeout(() => {
            this.sendEmail = false
          }, 5000)
        }
        this.recoverPassword = false
      } catch (error) {
        this.emailPasswordInvalid = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill,
::v-deep input:-webkit-autofill:hover,
::v-deep input:-webkit-autofill:focus,
::v-deep input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #000000 !important;
  transition: background-color 5000s ease-in-out 0s !important;
  padding-top: 14px !important;
}
::v-deep .v-list-item__icon:first-child {
  margin-right: 8px !important;
  margin-top: 20px;
}
::v-deep .v-text-field input {
  padding: 13px 0 8px;
  color: #5b6459;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
::v-deep .v-text-field--outlined fieldset {
  top: 0 !important;
}
::v-deep .v-list-item__content {
  flex: 15 1;
}
::v-deep .v-input__control {
  height: 0px;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
::v-deep hr {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0.4;
}
.header-login,
.header-login_language-selector {
  height: 74px;
  max-height: 74px;
}
.empty-alert {
  height: 72px;
  width: auto;
}
.welcome-text {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.select-account {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 35px;
}
.recover-password {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.welcome-secundary-text {
  color: #51594f;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.options-label {
  color: #253521;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: -3px;
}
.custom-select {
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  height: 30px;
  min-width: 100px;
}
.icon-outlined {
  color: #788476;
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.farmer-img__wrapper {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  margin: 12px 0px !important;

  .login-side-image {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
}
.background-help {
  background-color: #e3f8e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px !important;
  height: 45px !important;
}
.text-link {
  color: #788476;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.contact {
  color: #5b6459;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.icon-access {
  color: #fff !important;
  font-size: 10px;
  margin-left: 5px;
}
.platform-access-button {
  background-color: #39af49 !important;
}
.platform-access {
  color: #fff;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
}
.icon-link {
  color: #5b6459;
  font-size: 10px;
  margin-left: 5px;
}
.access-account {
  padding-top: 27px;
}
</style>
