<template>
  <v-row class="footer-login d-flex flex-column">
    <v-col class="d-flex align-start divider-custom">
      <v-divider color="grey darken-3 py-1"></v-divider>
    </v-col>
    <v-col>
      <v-row>
        <v-col class="d-flex justify-start">
          <span v-if="!isMobile" class="help-icon mr-2">?</span>
          <div>
            <div class="help-heading">{{ $t('login.need_help') }}</div>
            <div class="help-subheading">{{ $t('login.contact_us') }}</div>
          </div>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <v-btn
            v-if="isBrazil"
            small
            class="text-none text-button-action mr-2"
            @click="contactCommercial"
          >
            {{ $t('commercial') }}
            <v-icon class="icon-phone" color="#39af49">mdi-phone</v-icon>
          </v-btn>
          <v-btn
            v-if="isBrazil || isSpanish"
            small
            class="text-none text text-button-action"
            @click="contactSupport"
          >
            {{ $t('support') }}
            <v-icon class="icon-phone" color="#39af49">mdi-phone</v-icon>
          </v-btn>

          <div v-if="isUsa" class="custom-button">
            {{ USANumber }}
            <v-icon class="icon-phone" color="#39af49">mdi-phone</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin'

export default {
  mixins: [LayoutMixin],
  data() {
    return {
      language: null,
    }
  },

  computed: {
    isBrazil() {
      return this.language === 'pt'
    },

    isSpanish() {
      return this.language === 'es'
    },

    isUsa() {
      return this.language === 'en'
    },

    commercialWhatsapp() {
      return (
        process.env.VUE_APP_COMMERCIAL_WHATSAPP +
        this.$t('contato.msg_whatsapp_login_commercial')
      )
    },

    supportWhatsapp() {
      return (
        process.env.VUE_APP_SUPPORT_WHATSAPP_BR +
        this.$t('contato.msg_whatsapp_login_support')
      )
    },

    USANumber() {
      return process.env.VUE_APP_SUPPORT_EN
    },
  },

  created() {
    this.language = this.$root.$i18n.locale
  },

  watch: {
    '$root.$i18n.locale': function (language) {
      this.language = language
    },
  },

  methods: {
    contactCommercial() {
      window.open(this.commercialWhatsapp).focus()
    },

    contactSupport() {
      window.open(this.supportWhatsapp).focus()
    },
  },
}
</script>

<style scoped>
::v-deep hr {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0.4;
}

.footer-login {
  height: 160px;
  max-height: 160px;
}

.divider-custom {
  max-height: 30px;
}

.custom-button {
  font-family: 'Rubik';
  padding: 8px 16px;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: default;
}

.custom-button:hover {
  background-color: #e0e0e0;
}

.custom-button .icon-phone {
  margin-left: 8px;
}

.help-icon {
  background-color: #e3f8e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px !important;
  height: 45px !important;
  text-align: center;
  font-family: 'Rubik';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #39af49;
}

.help-heading {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.help-subheading {
  color: #5b6459;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-button-action {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3f4e3c;
  background-color: #fff !important;
  letter-spacing: 0;
}

.icon-phone {
  font-size: 16px;
  margin-left: 5px;
}
</style>
