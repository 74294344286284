<template>
  <v-select
    class="language-selector-custom"
    v-model="selectedLanguage"
    item-text="name"
    item-value="value"
    outlined
    dense
    hide-details
    return-object
    color="#39af49"
    :items="languages"
    @change="changeLanguage"
  >
    <template #selection="{ item }">
      <v-list-item-icon>
        <img :src="item.flag" alt="Flag" width="30px" height="20px" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="language-name">{{
          item.name
        }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template #item="{ item }">
      <v-list-item-icon>
        <img :src="item.flag" alt="Flag" width="30px" height="20px" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="language-name">{{
          item.name
        }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { getBrowserLanguage } from '@/language'
import { localeOptions } from '@/language/locales'

export default {
  data() {
    return {
      selectedLanguage: null,
    }
  },

  created() {
    this.selectedLanguage = getBrowserLanguage()
  },

  computed: {
    languages() {
      return localeOptions
    },
  },

  methods: {
    changeLanguage() {
      this.$root.$i18n.locale = this.selectedLanguage.value
      localStorage.setItem('language', this.selectedLanguage.value)
    },
  },
}
</script>

<style scoped>
.language-name {
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.language-selector-custom {
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  height: 30px;
  min-width: 100px;
}
</style>
